import React from 'react';
import { BigNumber } from 'bignumber.js';
import classNames from 'classnames';
import { TokenAmountWithChainByAsset } from '@/shared/components/TokenWithChain';
import { type ChainflipAsset } from '@/shared/graphql/generated/graphql';
import RightDouble from '@/shared/icons/flip-ui-kit/small/RIghtDouble';
import { ArrowIcon } from '@/shared/icons/large';
import { TokenAmount } from '@/shared/utils';

export const SwapRouteArrow = ({ className }: { className?: string }) => (
  <div
    className={classNames(
      'relative m-auto h-[24px] w-[24px] rounded-full border-[0.75px] border-cf-gray-3-5 p-1 shadow-[0px_0px_0px_0.75px_#191919] [background:linear-gradient(180deg,rgba(255,255,255,0.06)_-19.26%,rgba(255,255,255,0.00)_60.89%),#2B2B2B]',
      className,
    )}
  >
    <RightDouble className="absolute bottom-[2.3px] right-[3px] text-cf-light-2" />
  </div>
);

type SwapAmountInfo = {
  depositAmount: string;
  depositValueUsd?: string | null;
  egressAmount?: string | null;
  egressValueUsd?: string | null;
  intermediateAmount?: string | null;
  intermediateValueUsd?: string | null;
};

type SwapStep = { asset: ChainflipAsset; amount?: string; amountUsd?: string };

const getSwapSteps = (
  swaps: SwapAmountInfo[],
  sourceAsset: ChainflipAsset,
  destinationAsset: ChainflipAsset,
): [SwapStep, SwapStep] | [SwapStep, SwapStep, SwapStep] => {
  const totals = swaps.reduce(
    (acc, swap) => {
      acc.depositAmount = acc.depositAmount.plus(swap.depositAmount);
      acc.depositAmountUSD = acc.depositAmountUSD.plus(swap.depositValueUsd ?? 0);
      acc.egressAmount = acc.egressAmount.plus(swap.egressAmount ?? 0);
      acc.egressAmountUSD = acc.egressAmountUSD.plus(swap.egressValueUsd ?? 0);
      acc.intermediateAmount = acc.intermediateAmount.plus(swap.intermediateAmount ?? 0);
      acc.intermediateAmountUSD = acc.intermediateAmountUSD.plus(swap.intermediateValueUsd ?? 0);
      return acc;
    },
    {
      depositAmount: new BigNumber(0),
      depositAmountUSD: new BigNumber(0),
      egressAmount: new BigNumber(0),
      egressAmountUSD: new BigNumber(0),
      intermediateAmount: new BigNumber(0),
      intermediateAmountUSD: new BigNumber(0),
    },
  );

  const sourceAmount = TokenAmount.fromAsset(totals.depositAmount, sourceAsset);
  const hasSourceAmount = (sourceAmount && !sourceAmount.eq(0)) || undefined;
  const sourceAmounts = {
    asset: sourceAsset,
    amount: hasSourceAmount && sourceAmount?.toFixedDisplay(),
    amountUsd: hasSourceAmount && totals.depositAmountUSD.toFixed(),
  };

  const destinationAmount = TokenAmount.fromAsset(totals.egressAmount, destinationAsset);
  const hasDestinationAmount = (destinationAmount && !destinationAmount.eq(0)) || undefined;
  const destinationAmounts = {
    asset: destinationAsset,
    amount: hasDestinationAmount && destinationAmount?.toFixedDisplay(),
    amountUsd: hasDestinationAmount && totals.egressAmountUSD.toFixed(),
  };

  const intermediateAmount = TokenAmount.fromAsset(totals.intermediateAmount, 'Usdc');
  const hasIntermediateAmount = (intermediateAmount && !intermediateAmount.eq(0)) || undefined;
  const intermediateAmounts = {
    asset: 'Usdc' as ChainflipAsset,
    amount: hasIntermediateAmount && intermediateAmount?.toFixedDisplay(),
    amountUsd: hasIntermediateAmount && totals.intermediateAmountUSD.toFixed(),
  };

  if (sourceAsset === 'Usdc' || destinationAsset === 'Usdc') {
    return [sourceAmounts, destinationAmounts];
  }

  return [sourceAmounts, intermediateAmounts, destinationAmounts];
};

export const SwapRoute = ({
  routeInfo: { sourceAsset, destinationAsset },
  swaps,
}: {
  routeInfo: {
    sourceAsset: ChainflipAsset;
    destinationAsset: ChainflipAsset;
  };
  swaps?: SwapAmountInfo[];
}) => {
  if (!sourceAsset || !destinationAsset || !swaps) return null;
  const steps = getSwapSteps(swaps, sourceAsset, destinationAsset);

  return (
    <div className="grid grid-cols-2 flex-wrap items-center justify-between gap-y-3 md:flex">
      {steps.map((step, index) => (
        <React.Fragment key={step.asset}>
          <div className="min-w-[37px]">
            <TokenAmountWithChainByAsset
              asset={step.asset}
              amount={step.amount}
              usdAmount={step.amountUsd}
              amountClass="text-12 lg:text-14 text-white"
              displayChainLogo={!(index === 1 && steps.length > 2)}
            />
          </div>

          <SwapRouteArrow className={index + 1 === steps.length ? 'hidden' : 'block'} />
        </React.Fragment>
      ))}
    </div>
  );
};

export const CompactSwapRoute = ({
  routeInfo: { sourceAsset, destinationAsset },
  swaps = [],
  highlightAssets,
  flat = false,
}: {
  routeInfo: {
    sourceAsset: ChainflipAsset;
    destinationAsset: ChainflipAsset;
  };
  swaps?: SwapAmountInfo[];
  highlightAssets?: ChainflipAsset[];
  flat?: boolean;
}) => {
  if (!sourceAsset || !destinationAsset || !swaps) return null;
  const steps = getSwapSteps(swaps, sourceAsset, destinationAsset);

  return (
    <div
      className={classNames(
        'flex items-center',
        flat ? 'gap-x-1' : 'gap-x-2 rounded-[6px] border border-cf-gray-4 bg-cf-gray-3 px-2 py-1.5',
      )}
    >
      {steps.map((step, index) => (
        <React.Fragment key={step.asset}>
          <TokenAmountWithChainByAsset
            asset={step.asset}
            amount={step.amount}
            amountClass="text-12 text-white font-aeonikMono"
            displayChainLogo={!(index === 1 && steps.length > 2)}
            size="small"
            compact
            disabled={highlightAssets && !highlightAssets.includes(step.asset)}
          />
          <ArrowIcon
            width={16}
            className={classNames(
              'text-cf-light-2',
              index + 1 === steps.length ? 'hidden' : 'block',
            )}
          />
        </React.Fragment>
      ))}
    </div>
  );
};
