import { useEffect, useRef } from 'react';
import qrcode from 'qrcode';

export const QRCode = ({
  content,
  width,
  icon,
}: {
  content: string;
  width: number;
  icon?: React.ReactNode;
}) => {
  const ref = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (ref.current && width) {
      qrcode.toCanvas(ref.current, content, { margin: 1, width });
    }
  }, [content, width]);

  return (
    <div className="flex items-center justify-center">
      {icon}
      <canvas className="w-full" ref={ref} />
    </div>
  );
};
